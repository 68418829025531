import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const icons = {    
  BookOutlinedIcon,
  SchoolOutlinedIcon
};

const pages = {
  id: "application",
  title: "Applications",
  type: "group",
  children: [
    {
      id: "admissions",
      title: "Admissions",
      type: "item",
      url: "/admissions",
      icon: icons.SchoolOutlinedIcon,
      breadcrumbs: true,      
    },
    // {
    //   id: "entrance-exams",
    //   title: "Entrance Examinations",
    //   type: "item",
    //   url: "/entrance-exams",
    //   icon: icons.BookOutlinedIcon,
    //   breadcrumbs: true,      
    // },     
  ]
};

export default pages;