export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  COMPLETE_PROFILE_REQUEST: "COMPLETE_PROFILE_REQUEST",
  COMPLETE_PROFILE_SUCCESS: "COMPLETE_PROFILE_SUCCESS",
  COMPLETE_PROFILE_FAILURE: "COMPLETE_PROFILE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",
  MANAGE_DOCUMENT_REQUEST: "MANAGE_DOCUMENT_REQUEST",
  MANAGE_DOCUMENT_SUCCESS: "MANAGE_DOCUMENT_SUCCESS",
  MANAGE_DOCUMENT_FAILURE: "MANAGE_DOCUMENT_FAILURE",  
  SET_SELECTED_COLLEGE: "SET_SELECTED_COLLEGE",
  UPDATE_MY_ADMISSIONS: "UPDATE_MY_ADMISSIONS",
  SET_REDIRECT: "SET_REDIRECT",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const dataConstants = {
  GET_ADMISSIONS_REQUEST: "GET_ADMISSIONS_REQUEST",
  GET_ADMISSIONS_SUCCESS: "GET_ADMISSIONS_SUCCESS",
  GET_ADMISSIONS_FAILURE: "GET_ADMISSIONS_FAILURE",
  GET_ADMISSION_REQUEST: "GET_ADMISSION_REQUEST",
  GET_ADMISSION_SUCCESS: "GET_ADMISSION_SUCCESS",
  GET_ADMISSION_FAILURE: "GET_ADMISSION_FAILURE",
  GET_ADMISSION_APPLY_DATA_REQUEST: "GET_ADMISSION_APPLY_DATA_REQUEST",
  GET_ADMISSION_APPLY_DATA_SUCCESS: "GET_ADMISSION_APPLY_DATA_SUCCESS",
  GET_ADMISSION_APPLY_DATA_FAILURE: "GET_ADMISSION_APPLY_DATA_FAILURE",
  APPLY_ADMISSION_REQUEST: "APPLY_ADMISSION_REQUEST",
  APPLY_ADMISSION_SUCCESS: "APPLY_ADMISSION_SUCCESS",
  APPLY_ADMISSION_FAILURE: "APPLY_ADMISSION_FAILURE",
  ATTACH_DOCS_TO_APPLICATION_REQUEST: "ATTACH_DOCS_TO_APPLICATION_REQUEST",
  ATTACH_DOCS_TO_APPLICATION_SUCCESS: "ATTACH_DOCS_TO_APPLICATION_SUCCESS",
  ATTACH_DOCS_TO_APPLICATION_FAILURE: "ATTACH_DOCS_TO_APPLICATION_FAILURE",
  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}