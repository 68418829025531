const states = [
  { "value": "uttarakhand", "label": "Uttarakhand" },
  { "value": "andhra-pradesh", "label": "Andhra Pradesh" },
  { "value": "arunachal-pradesh", "label": "Arunachal Pradesh" },
  { "value": "assam", "label": "Assam" },
  { "value": "bihar", "label": "Bihar" },
  { "value": "chhattisgarh", "label": "Chhattisgarh" },
  { "value": "goa", "label": "Goa" },
  { "value": "gujarat", "label": "Gujarat" },
  { "value": "haryana", "label": "Haryana" },
  { "value": "himachal-pradesh", "label": "Himachal Pradesh" },
  { "value": "jammu-and-kashmir", "label": "Jammu and Kashmir" },
  { "value": "jharkhand", "label": "Jharkhand" },
  { "value": "karnataka", "label": "Karnataka" },
  { "value": "kerala", "label": "Kerala" },
  { "value": "madhya-pradesh", "label": "Madhya Pradesh" },
  { "value": "maharashtra", "label": "Maharashtra" },
  { "value": "manipur", "label": "Manipur" },
  { "value": "meghalaya", "label": "Meghalaya" },
  { "value": "mizoram", "label": "Mizoram" },
  { "value": "nagaland", "label": "Nagaland" },
  { "value": "odisha", "label": "Odisha" },
  { "value": "punjab", "label": "Punjab" },
  { "value": "rajasthan", "label": "Rajasthan" },
  { "value": "sikkim", "label": "Sikkim" },
  { "value": "tamil-nadu", "label": "Tamil Nadu" },
  { "value": "telangana", "label": "Telangana" },
  { "value": "tripura", "label": "Tripura" },
  { "value": "uttar-pradesh", "label": "Uttar Pradesh" },  
  { "value": "west-bengal", "label": "West Bengal" },
]

const courseMode = [
  { "value": "YEARLY", "label": "Yearly" },
  { "value": "SEMESTER", "label": "Semester" },
]

const studentDocumentTypes = [
  { "value": "PHOTO", "label": "Photo" },
  { "value": "SIGNATURE", "label": "Signature" },
  { "value": "MARKSHEET", "label": "Marksheet" },
  { "value": "CERTIFICATE", "label": "Certificate" },
  { "value": "IDENTITY_DOCUMENT", "label": "Identity Document" },
  { "value": "OTHER", "label": "Other" },
]

const disabilityTypes = [
  { "value": "BLIND", "label": "Blind" },
  { "value": "DEAF", "label": "Deaf" },
  { "value": "DUMB", "label": "Dumb" },
  { "value": "PHYSICALLY_HANDICAPPED", "label": "Physically Handicapped" },
  { "value": "OTHER", "label": "Other" },
]

const genders = [
  { "value": "male", "label": "Male" },
  { "value": "female", "label": "Female" },
  { "value": "other", "label": "Others" },
]

const religions = [
  { "value": "hindu", "label": "Hindu" },
  { "value": "muslim", "label": "Muslim" },
  { "value": "christian", "label": "Christian" },
  { "value": "sikh", "label": "Sikh" },
  { "value": "buddhist", "label": "Buddhist" },
  { "value": "jain", "label": "Jain" },
  { "value": "other", "label": "Other" },
]

const nationalities = [
  { "value": "indian", "label": "Indian" },
  { "value": "nri", "label": "NRI" },
  { "value": "other", "label": "Other" },
]

const bloodGroups = [
  { "value": "A+", "label": "A+" },
  { "value": "A-", "label": "A-" },
  { "value": "B+", "label": "B+" },
  { "value": "B-", "label": "B-" },
  { "value": "AB+", "label": "AB+" },
  { "value": "AB-", "label": "AB-" },
  { "value": "O+", "label": "O+" },
  { "value": "O-", "label": "O-" },
]

const martialStatus = [
  { "value": "married", "label": "Married" },
  { "value": "unmarried", "label": "Unmarried" },
]

const identityDocTypes = [
  { "value": "aadhaar", "label": "Aadhaar" },
  { "value": "pan", "label": "PAN" },
  { "value": "passport", "label": "Passport" },
  { "value": "voter-id", "label": "Voter ID" },
  { "value": "driving-license", "label": "Driving License" },  
]

const marksheetTypes = [
  { "value": "10th", "label": "10th" },
  { "value": "12th", "label": "12th" },
  { "value": "diploma", "label": "Diploma" },
  { "value": "graduation", "label": "Graduation" },
  { "value": "post-graduation", "label": "Post Graduation" },  
]

const applicationStatus = [
  { "value": "APPLIED", "label": "Applied", "color": "warning" },
  { "value": "APPROVED", "label": "Approved", "color": "success" },
  { "value": "REJECTED", "label": "Rejected", "color": "error" },
  { "value": "WITHDRAWN", "label": "Withdrawn", "color": "error" },
]

const paymentGateways = [
  // { "value": "STRIPE", "label": "Stripe", image: "/assets/stripe.png" },
  // { "value": "RAZORPAY", "label": "Razorpay", image: "/assets/razorpay.png" },
  // { "value": "PAYTM", "label": "Paytm", image: "/assets/paytm.png" },
]

const personCategories = [
  { "value": "GENERAL", "label": "General" },
  { "value": "OBC", "label": "OBC" },
  { "value": "SC", "label": "SC" },
  { "value": "ST", "label": "ST" },
  { "value": "EWS", "label": "EWS" },
  { "value": "PWD", "label": "PWD" },
]

module.exports = {
  states,
  courseMode,  
  studentDocumentTypes,
  disabilityTypes,
  genders,
  religions,
  nationalities,
  bloodGroups,
  martialStatus,
  identityDocTypes,
  marksheetTypes,
  applicationStatus,
  paymentGateways,
  personCategories
}