import { appConstants, dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const getAdmissions = (college) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ADMISSIONS_REQUEST });      
      let URL = `/applicant/application?type=admission&college=${college}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_ADMISSIONS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_ADMISSIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAdmissionDetails = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ADMISSION_REQUEST });      
      let URL = `/applicant/application/${id}?type=admission`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_ADMISSION_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_ADMISSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAdmissionApplyData = (id, admissionId) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ADMISSION_APPLY_DATA_REQUEST });      
      let URL = `/applicant/admission`;
      if(id){
        URL += `/${id}`;
      }else if(id && admissionId){
        URL += `/${id}?admission=${admissionId}`;      
      }else{
        URL += `?admission=${admissionId}`;
      }
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_ADMISSION_APPLY_DATA_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_ADMISSION_APPLY_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const applyAdmission = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.APPLY_ADMISSION_REQUEST });      
      let URL = `/applicant/admission`;
      const { data } = await Axios.post(URL, form);            
      dispatch({ 
        type: dataConstants.APPLY_ADMISSION_SUCCESS,
        payload: {data: data?.data, message: data?.message}
      });
      dispatch({
        type: appConstants.UPDATE_MY_ADMISSIONS,
        payload: data?.data
      })
      dispatch(getAdmissionApplyData(data?.data?.id, null));
    }catch(error){
      dispatch({ 
        type: dataConstants.APPLY_ADMISSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const attachDocsToApplication = (id, form, type) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.ATTACH_DOCS_TO_APPLICATION_REQUEST });      
      let URL = `/applicant/application/document/${id}?type=${type}`;
      const { data } = await Axios.post(URL, form);
      dispatch({ 
        type: dataConstants.ATTACH_DOCS_TO_APPLICATION_SUCCESS,
        payload: {message: data?.message}
      });
      if(type === "admission"){
        dispatch(getAdmissionApplyData(id, null));
      }
    }catch(error){
      dispatch({ 
        type: dataConstants.ATTACH_DOCS_TO_APPLICATION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getTransactions = (page, limit, type) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_TRANSACTIONS_REQUEST });      
      let URL = `/applicant/transactions?page=${page}&limit=${limit}&type=${type}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_TRANSACTIONS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_TRANSACTIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}