import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  admissions: [],
  admission: null,
  admissionApplyData: null,
  transactionData: null,
  admissionData: null
};

const dataReducer = (state = initState, action) => {  
  switch (action.type) {    
    case dataConstants.GET_ADMISSIONS_REQUEST:    
    case dataConstants.GET_ADMISSION_REQUEST:
    case dataConstants.GET_ADMISSION_APPLY_DATA_REQUEST:
    case dataConstants.APPLY_ADMISSION_REQUEST:
    case dataConstants.ATTACH_DOCS_TO_APPLICATION_REQUEST:
    case dataConstants.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case dataConstants.GET_ADMISSIONS_SUCCESS:
      return {
        ...state,      
        admissions: action.payload,
        loading: false,
      };

    case dataConstants.GET_ADMISSION_SUCCESS:
      return {
        ...state,      
        admission: action.payload,
        loading: false,
      };      

    case dataConstants.GET_ADMISSION_APPLY_DATA_SUCCESS:
      return {
        ...state,      
        admissionApplyData: action.payload,
        loading: false,
      };

    case dataConstants.APPLY_ADMISSION_SUCCESS:
    case dataConstants.ATTACH_DOCS_TO_APPLICATION_SUCCESS:
      return {
        ...state,      
        message: action.payload.message,
        loading: false,
      };

    case dataConstants.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,      
        transactionData: action.payload,
        loading: false,
      };
        
    case dataConstants.GET_ADMISSIONS_FAILURE:
    case dataConstants.GET_ADMISSION_FAILURE:
    case dataConstants.GET_ADMISSION_APPLY_DATA_FAILURE:
    case dataConstants.APPLY_ADMISSION_FAILURE:
    case dataConstants.ATTACH_DOCS_TO_APPLICATION_FAILURE:
    case dataConstants.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };   

    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;