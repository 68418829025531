import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));

const Admissions = Loadable(lazy(() => import("pages/admissions")));
const AdmissionDetails = Loadable(lazy(() => import("pages/admissions/admission-details")));
const AdmissionForm = Loadable(lazy(() => import("pages/admissions/admission-form")));

const MyDocuments = Loadable(lazy(() => import("pages/my-data/documents")));
const MyAdmissions = Loadable(lazy(() => import("pages/my-data/admissions")));
const MyTransactions = Loadable(lazy(() => import("pages/my-data/transactions")));
const Profile = Loadable(lazy(() => import("pages/profile")));

const PrivateRoute = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated, user } = useSelector(state => state.user);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : !user?.profileCompleted ? <Navigate to={`/complete-profile`} /> : children); 
};

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "",
      element: <Dashboard/>
    },
    {
      path: "admissions",      
      children: [
        {
          path: "",
          element: <Admissions/>
        },
        {
          path: ":id",
          element: <AdmissionDetails/>
        },
        {
          path: "apply/:id",
          element: <AdmissionForm/>
        }
      ]
    },
    {
      path: "my-data",      
      children: [
        {
          path: "documents",
          element: <MyDocuments/>
        },
        {
          path: "admissions",
          element: <MyAdmissions/>
        },
        {
          path: "transactions",
          element: <MyTransactions/>
        }      
      ]
    },
    {
      path: "profile",
      element: <Profile/>
    }  
  ]
};

export default MainRoutes;