import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  categories: [],
  session: null,
  universities: [],
  univerity: null, 
  myDocs: [],
  myAdmissions: [],
  recentAdmTransactions: [],
  selectedCollege: null,
  redirect: "" 
};

const appReducer = (state = initState, action) => {    
  let temp = null;
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:  
    case appConstants.MANAGE_DOCUMENT_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:  
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,      
        categories: action.payload.categories,
        session: action.payload.session,
        universities: action.payload.universities,
        univerity: action.payload.universities[0] || null,  
        myAdmissions: action.payload?.myAdmissions || [],
        myDocs: action.payload?.myDocuments || [],
        recentAdmTransactions: action.payload?.recentAdmTransactions || [],
        loading: false,
      };

    case appConstants.MANAGE_DOCUMENT_SUCCESS:
      temp = state.myDocs;
      let docIndex = temp.findIndex(doc => doc?.id === action.payload?.data?.id);
      if(docIndex !== -1){
        temp[docIndex] = action.payload?.data;
      }else{
        temp.push(action.payload?.data);
      }
      return {
        ...state,      
        message: action.payload.message,
        myDocs: temp,
        loading: false,
      };

    case appConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,      
        message: action.payload?.message,
        loading: false,
      };
        
    case appConstants.GET_INITIAL_DATA_FAILURE: 
    case appConstants.MANAGE_DOCUMENT_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };   

    case appConstants.SET_SELECTED_COLLEGE:
      return {
        ...state,
        selectedCollege: action.payload
      }

    case appConstants.UPDATE_MY_ADMISSIONS:
      temp = state.myAdmissions;
      let index = temp.findIndex(adm => adm?.id === action.payload?.id);
      if(index !== -1){
        temp[index] = action.payload;
      }else{
        temp.push(action.payload);
      }
      return {
        ...state,
        myAdmissions: temp
      }      

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;