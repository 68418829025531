import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

const icons = {    
  ConfirmationNumberOutlinedIcon,
  ExitToAppOutlinedIcon,
  FolderSharedOutlinedIcon,
  ReceiptLongOutlinedIcon
};

const myData = {
  id: "my-data",
  title: "My Data",
  type: "group",
  children: [
    {
      id: "my-admissions",
      title: "My Admissions",
      type: "item",
      url: "/my-data/admissions",
      icon: icons.ConfirmationNumberOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "my-docs",
      title: "My Documents",
      type: "item",
      url: "/my-data/documents",
      icon: icons.FolderSharedOutlinedIcon,
      breadcrumbs: true,      
    },    
    // {
    //   id: "my-entrance-exams",
    //   title: "My Entrance Examinations",
    //   type: "item",
    //   url: "/my-entrance-exams",
    //   icon: icons.ExitToAppOutlinedIcon,
    //   breadcrumbs: true,      
    // },
    {
      id: "my-transactions",
      title: "My Transactions",
      type: "item",
      url: "/my-data/transactions",
      icon: icons.ReceiptLongOutlinedIcon,
      breadcrumbs: true,
    }     
  ]
};

export default myData;