import { appConstants } from "redux/constants";
import Axios from "utils/axios";

export const getInitialData = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/applicant/initial-data`);            
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageDocument = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.MANAGE_DOCUMENT_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/applicant/document/${id}`, form);
      }else{
        _data = await Axios.post(`/applicant/document`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: appConstants.MANAGE_DOCUMENT_SUCCESS,
        payload: { message: data?.message, data: data?.data } 
      });      
    }catch(error){
      dispatch({ 
        type: appConstants.MANAGE_DOCUMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updatePassword = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PASSWORD_REQUEST });
      const { data } = await Axios.put(`/auth/applicant/password`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}
