import { lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Loadable from "components/Loadable";

const CompleteProfile = Loadable(lazy(() => import("pages/complete-profile")));

const PrivateRoute = ({ children }) => {
  const { userLoading, isAuthenticated, user } = useSelector(state => state.user);
  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${window.location.pathname}`} />
    : user?.profileCompleted ? <Navigate to={`/`} /> : children); 
};

const CompleteProfileRoutes = {
  path: "/complete-profile",
  element: <PrivateRoute>
    <CompleteProfile/>
  </PrivateRoute>, 
};

export default CompleteProfileRoutes;